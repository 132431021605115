<template>
  <!--TODO: BERECHTIGUNG -->
  <div class="row pt-1" v-if="berechtigungen.m_bildung.read">
    <div class="col-lg-12">
      <div class="tile-categorie-frame">
        <div class="tile-categorie">
          <div class="header">
            <span>News</span>
          </div>
          <div class="body">
            <div class="row">
              <div :class="kachelCol">
                <div class="tile-frame h-2" :class="kachelStyle">
                  <router-link :to="{ name: 'news-liste' }">
                    <div class="tile bg-light-brown inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-newspaper"
                          transform="shrink-2"
                        />
                      </div>
                      <div class="title">News</div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
	
<script>
export default {
  name: "StartseiteNews",
  mixins: [],
  props: {
    kachelStyle: { type: String },
    kachelCol: { type: String },
    kachelKleinStyle: { type: String },
    kachelKleinCol: { type: String },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>
	