var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.berechtigungen.m_lead.read)?_c('div',[_c('div',{staticClass:"row pt-1"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"tile-categorie-frame"},[_c('div',{staticClass:"tile-categorie"},[_c('div',{staticClass:"header"},[_c('span',[_vm._v(_vm._s(_vm.$t("dashboard.leadsbystage")))])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('column-chart',{attrs:{"data":_vm.statistikLeadsByStage,"height":"300px","prefix":"CHF","thousands":"'","colors":[
                    '#add58c',
                    '#1ad7e1',
                    '#ade0ec',
                    '#c3c4e2',
                    '#fccb9d',
                    '#f4f3d5',
                  ],"legend":false}})],1)])])])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }